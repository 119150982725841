import React, { useState, useEffect } from "react";
import Sidebar from "../components/client-dashboard/Sidebar";
import EditProfile from "../components/client-dashboard/EditProfile";
import EditCocktails from "../components/client-dashboard/SelectCocktails";
import EditTrivia from "../components/client-dashboard/EditTrivia";
import firebase from '../firebase';



  // ACCESS FIREBASE
  const db = firebase.firestore();
  const userID = "Mp8lPhiDFXX0wPxkLxgJ"; // This is hardcoded ---- need to be dynamic
  const profileRef = db.collection("SupplyKit").doc("Events").collection("Profiles").doc(userID);
  


function ClientDashboard() {




// create a state variable to hold the profile data
const [profileData, setProfileData] = useState(null);


// fetch the profile data using useEffect
useEffect(() => {
  profileRef.get().then((doc) => {
    if (doc.exists) {
      const data = doc.data();
      setProfileData(data); // set the profile data in state
    } else {
      console.log("No such document!");
    }
  }).catch((error) => {
    console.log("Error getting document:", error);
  });
}, [profileRef]);

  
  const [activeTab, setActiveTab] = useState("personalDetails");

  const handleButtonClick = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "personalDetails":
        return <EditProfile profileRef={profileRef}/>;
      case "cocktails":
        return <EditCocktails profileRef={profileRef} profileData={profileData} />;
      case "trivia":
        return <EditTrivia profileRef={profileRef}/>;
      default:
        return null;
    }
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar activeTab={activeTab} handleButtonClick={handleButtonClick} />
      <div className="mt-10 lg:mt-0 flex-1 bg-slate-100 p-20 min-h-screen">{renderContent()}</div>
    </div>
  );
}

export default ClientDashboard;