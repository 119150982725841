function TextInput(props) {
    const { type, label, value, onChange, placeholder } = props;
  
    return (
      <div className="mb-2">
        <label className="block">{label}</label>
        {type === "text" && (
          <input 
            className="block p-2 w-full border-solid border-2 border-sky-100 border-b-4" 
            type="text" 
            value={value} 
            onChange={onChange} 
            placeholder={placeholder} 
          />
        )}

        {type === "date" && (
          <input 
          className="block p-2 w-full border-solid border-2 border-sky-100 border-b-4" 
          type="date" 
            value={value} 
            onChange={onChange} 
            placeholder={placeholder} 
          />
        )}
      </div>
    );
  }
  
  export default TextInput;
  