import React from 'react'


function Login() {
  return (
    <div className='grid h-screen place-content-center bg-slate-800'>
    <div className='mx-auto shadow-xl rounded-xl p-20 bg-white'>
        <h1 className="">Please Log In To Continue
        
        </h1>
        </div>
        </div>
  )
}

export default Login