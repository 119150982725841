import React from 'react'

function HeaderSection({ title, textAlign}) {
  return (
    <div className="p-4 mb-10">
      <h1 className={`text-3xl text-sky-700 font-bold ${textAlign}`}>{title}</h1>
    </div>
  );
}

HeaderSection.defaultProps = {
  textAlign: "text-center",
}

export default HeaderSection