import React from 'react'

function Card({ title, onClick, children }) {
    
  return (
    <>
    <div onClick={onClick} className='my-5 p-6 max-w-md mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 text-gray-700 '>
        <h1 className='font-bold text-center'>{title}</h1>
        <div>
        {children}
        </div>
    </div>



</>



  )
}

export default Card