import TriviaQuestions from "../TriviaQuestions"
import HeaderSection from "../shared/HeaderSection"

function EditTrivia() {
  return (
    <div>
        <HeaderSection title='Edit Your Trivia Questions' />

        <TriviaQuestions />



    </div>
  )
}

export default EditTrivia