import { useEffect, useState } from "react";
import HeaderSection from "../shared/HeaderSection";
import DefaultTriviaQuestions from '../../data/DefaultTriviaQuestions';
import TextInput from "../shared/form/TextInput";

function EditProfile({ profileRef, profileData }) {

  // STATES
  const [firstName, setFirstName] = useState('')
  const [coupleNames, setCoupleNames] = useState('')
  const [shippingDate, setShippingDate] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [shippingAddress, setShippingAddress] = useState('')
  const [orderQuantity, setOrderQuantity] = useState('')

  // HANDLE CHANGES
  const handleNamesChange = (e) => {
    setCoupleNames(e.target.value)
  }

  const handleAddressChange = (e) => {
    setShippingAddress(e.target.value)
  }
  const handleShippingDateChange = (e) => {
    setShippingDate(e.target.value)
  }
  const handleEventDateChange = (e) => {
    setEventDate(e.target.value)
  }

  // UPDATE PROFILE DATA
  const handleSubmit = (e) => {
    e.preventDefault();

    profileRef.update({
      coupleNames,
      shippingAddress,
      shippingDate,
      eventDate,
    })
    .then(() => {
      console.log("Profile updated successfully");
    })
    .catch((error) => {
      console.error("Error updating profile: ", error);
    });
  }

  // FETCH PROFILE DATA
  useEffect(() => {
    profileRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setFirstName(data.firstName);
        setCoupleNames(data.coupleNames);
        setShippingDate(data.shippingDate);
        setEventDate(data.eventDate);
        setShippingAddress(data.shippingAddress);
        setOrderQuantity(data.orderQuantity);
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [profileRef]);

  // FORMAT TIMESTAMP
  const formattedShippingDate = shippingDate ? new Date(shippingDate.seconds * 1000).toLocaleDateString('en-GB', {day: 'numeric', month: 'long', year: '2-digit'}) : null;
  const formattedEventDate = eventDate ? new Date(eventDate.seconds * 1000).toLocaleDateString('en-GB', {day: 'numeric', month: 'long', year: '2-digit'}) : null;

  const generateQuestions = () => {
    profileRef.collection("questions").get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("User has no questions, generating default data");
        DefaultTriviaQuestions.forEach((question, index) => {
          const itemRef = profileRef.collection("questions").doc(`question${index}`);
          itemRef.set(question);
        });
      } else {
        console.log("User already has questions");
      }
    }).catch((error) => {
      console.log("Error getting questions subcollection: ", error);
    });
  }


  return (
    <>

    <div className='max-w-5xl mx-auto'>
      <HeaderSection title={`Welcome, ${firstName}!`} />
      <h2>This is where you can manage your booking and select which cocktails you would like!</h2>
  
      <form>
  
        <p>Let's get a few more details from you:</p>
  
        {formattedShippingDate && (
          <h3>Your order will be shipped on  {formattedShippingDate}</h3>
        )}
  
        <h3>You have ordered {orderQuantity} units</h3>
  
  
        <div>
        <TextInput type='text' value={coupleNames} onChange={handleNamesChange} label='Your names, as you would like them to appear on the label' placeholder='Please enter your names' />
        <TextInput type='date' value={shippingDate} onChange={handleShippingDateChange} label='Approximate shipping date' placeholder='' />
        <TextInput type='date' value={eventDate} onChange={handleEventDateChange} label='Event Date' placeholder='' />
        <TextInput type='text' value={shippingAddress} onChange={handleAddressChange} label='Address for Delivery' placeholder='Please enter delivery address' />
         
            <button
              className='bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mt-4'
              type='submit'
              onClick={handleSubmit}>
              Submit
            </button>

  
        </div>
  
      </form>
    </div>
    
  </>
  
  )
}

export default EditProfile