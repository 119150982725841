import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBF4ll1DyCjqauBW8BejXXuKcpN4axi_00",
  authDomain: "supply-kit-ab8f4.firebaseapp.com",
  projectId: "supply-kit-ab8f4",
  storageBucket: "supply-kit-ab8f4.appspot.com",
  messagingSenderId: "762775364286",
  appId: "1:762775364286:web:efa6067ebc17fbbe9bb4ef",
  measurementId: "G-GP45V3VMPZ"
};

firebase.initializeApp(firebaseConfig);

export default firebase;