import CocktailMenu from '../CocktailMenu'
import HeaderSection from '../shared/HeaderSection'

function EditCocktails({ profileRef, profileData }) {
  return (
    <>
      <HeaderSection title="Select Your Cocktails" />

      <p>You have ordered {profileData.orderQuantity} units</p>
      <CocktailMenu profileRef={profileRef} profileData={profileData} />

      <button />
    </>
  )
}

export default EditCocktails
