import React, { useEffect, useState } from 'react'
import firebase from '../firebase'

function CocktailMenu({ profileRef, profileData }) {
  const [cocktailMenu, setCocktailMenu] = useState([])
  const [quantityValues, setQuantityValues] = useState({})
  const [savedQuantities, setSavedQuantities] = useState({})
  const [saving, setSaving] = useState(false)
  const [cocktails, setCocktails] = useState([])

  const db = firebase.firestore()

  const handleQuantityChange = (cocktailId, event) => {
    setQuantityValues((prevValues) => ({
      ...prevValues,
      [cocktailId]: event.target.value,
    }))
  }

  const handleSave = () => {
    setSaving(true)
    const cocktailsOrdered = cocktailMenu.map((cocktail) => {
      const savedQuantity = savedQuantities[cocktail.id] || 0
      const userQuantity = quantityValues[cocktail.id] || 0
      const quantity = parseInt(savedQuantity, 10) + parseInt(userQuantity, 10)
      return {
        id: cocktail.name,
        quantity,
      }
    })
    const order = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      cocktailsOrdered,
    }
    profileRef
      .update({ cocktailsOrdered }) // Saves the order to the users profile
      .then(() => {
        console.log('Order saved successfully')
      })
      .catch((error) => {
        console.error('Error saving order: ', error)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  useEffect(() => {
    // Fetch cocktail menu
    db.collection('SupplyKit')
      .doc('Events')
      .collection('CocktailMenu')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data())
        setCocktailMenu(data)
      })

    // Retrieve cocktailsOrdered array from profileRef
    profileRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          const data = doc.data()
          setCocktails(data.cocktailsOrdered || [])
          const quantities = {}
          data.cocktailsOrdered?.forEach((cocktail) => {
            quantities[cocktail.id] = cocktail.quantity
          })
          setSavedQuantities(quantities)
        }
      },
      (error) => {
        console.error('Error retrieving cocktailsOrdered:', error)
      }
    )
  }, [profileRef])

  // Total Count
  const totalQuantity = Object.values(quantityValues).reduce(
    (acc, curr) => acc + parseInt(curr || 0, 10),
    0
  )

  return (
    <>
      <p>{totalQuantity} units selected</p>
      <p>{profileData.orderQuantity} units ordered</p>
      <p>Need to order more? Click here!</p>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
        {cocktailMenu.map((cocktail) => (
          <div key={cocktail.name}>
            
            <div className="flex bg-white shadow-xl rounded-2xl overflow-hidden">
              <div>
                <img src="https://placehold.co/300x300/EEEEEE/AAA" />
              </div>
              <div className='p-4'>
                <h2 className="text-xl">{cocktail.name}</h2>
                <p>{cocktail.description}</p>
                <p className="py-5">Cocktail ID: {cocktail.id}</p>
                <label htmlFor={`quantity-${cocktail.id}`}>Quantity:</label>
                <input
                  type="number"
                  id={`quantity-${cocktail.id}`}
                  name={`quantity-${cocktail.id}`}
                  min="0"
                  value="0"
                  onChange={(event) => handleQuantityChange(cocktail.id, event)}
                  className="b-1 border-black"
                />
              </div>
            </div>
          </div>
        ))}

        <ul>
          {cocktails.map((cocktail) => (
            <li key={cocktail.id}>
              {cocktail.id}: {cocktail.quantity}
            </li>
          ))}
        </ul>
        <button disabled={saving} onClick={handleSave}>
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </>
  )
}

export default CocktailMenu
