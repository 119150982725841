import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import EditProfile from './components/client-dashboard/EditProfile'
import EditTrivia from './components/client-dashboard/EditTrivia'
import EditCocktails from './components/client-dashboard/SelectCocktails'
import ClientDashboard from './pages/ClientDashboard'

import firebase from './firebase'
import Login from './pages/Login'

// ACCESS FIREBASE
const db = firebase.firestore()
const userID = 'Mp8lPhiDFXX0wPxkLxgJ' // This is hardcoded ---- need to be dynamic
const firebaseDoc = db.collection('SupplyKit').doc('Events')

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Login firebaseDoc={firebaseDoc} />
              </>
            }
          ></Route>

          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/edit-trivia" element={<EditTrivia />} />
          <Route path="/select-cocktails" element={<EditCocktails />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
