import React, { useEffect, useState } from "react";
import firebase from '../firebase';
import Card from "./shared/Card";

function TriviaQuestions() {
   
    const [activeQuestions, setActiveQuestions] = useState([]);
    const [inactiveQuestions, setInactiveQuestions] = useState([]);

    const db = firebase.firestore();
    const firestorePath = db.collection("SupplyKit").doc("Events").collection("Profiles").doc("Mp8lPhiDFXX0wPxkLxgJ").collection("questions");
    const profileID = "Mp8lPhiDFXX0wPxkLxgJ";

    useEffect(() => {
        firestorePath.onSnapshot((snapshot) => {
            const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setActiveQuestions(data.filter((question) => question.active));
            setInactiveQuestions(data.filter((question) => !question.active));
        }, (error) => {
            console.log("Error getting trivia questions: ", error);
        });
    }, []);

    const handleSetActive = (question) => {
        const questionRef = firestorePath.doc(question.id);
        questionRef.update({
            active: !question.active
        }).catch((error) => {
            console.log("Error updating question: ", error);
        });
    };
  
    return (
        <div className="container mx-auto text-center">
            <div className="grid grid-cols-2 gap-4 text-center">
            <div>
                <h1 className="text-2xl">Active Questions</h1>
                {activeQuestions.map((question) => (
                    <div className="py-5" key={question.id}>
                        <Card className="text-2xl" title={question.question}>
                        <button onClick={() => handleSetActive(question)}>Hide</button>    
                        </Card>
                    </div>
                ))}
            </div>
            <div>
                <h1 className="text-2xl">Inactive Questions</h1>
            {inactiveQuestions.map((question) => (
                <div className="py-5 opacity-50" key={question.id}>
                    <Card className="text-2xl" title={question.question} >
                    <button onClick={() => handleSetActive(question)}>Show</button>
                    </Card>
                    
                </div>
            ))}
            </div>
            </div>
        </div>
    );
}

export default TriviaQuestions;
